import React, { useState, useEffect }  from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import Menu from './menu.js'
import '../css/background.css';

Amplify.configure(awsExports);

function App() {
  return (
   <div class="anchor">
      <div class="name">
            Daniel Chang
      </div>
      <hr class="main-line"/>
      <Menu/>
    <div class='background'>
        <div class='circle xxlarge shade1'></div>
        <div class='circle xlarge shade2'></div>
        <div class='circle large shade3'></div>
        <div class='circle mediun shade4'></div>
        <div class='circle small shade5'></div>
    </div>
    </div>
  );
}

export default App;
