import React from 'react';
import resume from '../files/resume.pdf';
import '../css/App.css';

class Menu extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return(
		<div class="menu">
        	<div>
          		<a href={resume} 
          		target="_blank"
          		rel="noopener noreferrer">
          		Resume</a>
        	</div>
        	<div>
            	<a href="https://www.linkedin.com/in/daniel-chang-735075135/">Linkedin </a>
        	</div>
        	<div>
            	<a href="https://github.com/changhi">GitHub</a>
        	</div>
        	<div>
            	<a href="https://www.instagram.com/art_of_dan_chang/">Art</a>
        	</div>
      </div>)
	}
}
export default Menu;